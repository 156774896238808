import React from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import styles from './BannerImage.module.scss'

const BannerImage = ({ imageFluid, titleHtml }) => {
  return (
    <BackgroundImage Tag="section" fluid={imageFluid} backgroundColor="#000000">
      <div
        className={styles.container}
        dangerouslySetInnerHTML={{ __html: titleHtml }}
      />
    </BackgroundImage>
  )
}

BannerImage.propTypes = {
  imageFluid: PropTypes.object.isRequired,
  titleHtml: PropTypes.string.isRequired,
}

export default BannerImage
