import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  width: 100%;
`

const ContentWrapper = styled.div`
  max-width: 1180px;
  margin: auto;
`

const ContentContainer = ({ children }) => (
  <Wrapper>
    <ContentWrapper>{children}</ContentWrapper>
  </Wrapper>
)

ContentContainer.propTypes = {
  children: PropTypes.array,
}

ContentContainer.defaultProps = {
  children: [],
}

export default ContentContainer
