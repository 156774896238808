import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styles from './Footer.module.scss'

const Footer = ({ data }) => {
  return (
    <div>
      <div className={styles.footerContent}>
        <div
          className={styles.footerText}
          dangerouslySetInnerHTML={{ __html: data }}
        />
        <div className={styles.footerLinks}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </div>
      </div>
      <div className={styles.copyright}>
        <p>&copy;2021 Woodwork 52</p>
      </div>
    </div>
  )
}

Footer.propTypes = {
  data: PropTypes.string.isRequired,
}

export default Footer
