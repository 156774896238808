const theme = {
  colors: {
    primary: '#3D63AE',
    warning: '#f0ad4e',
    warningDark: '#eea236',
    success: '#3D63AE',
    bg: '#fff',
    white: '#fff',
    black: '#191919',
    greyLight: '#EBEDF2',
    greyBlue: '#a2bce2',
    grey: '#F5F5F5',
    greyDark: '#262729',
    greyDarker: '#262729',
    logoRed: '#810306',
  },
  maxWidth: '1000px',
  maxWidthText: '720px',
  breakpoints: {
    xs: '450px',
    s: '700px',
    m: '900px',
    l: '1200px',
  },
}

export default theme
