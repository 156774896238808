import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ContentContainer from './ContentContainer'
import theme from '../../styles/theme'

const PageSection = ({ children, color }) => {
  return (
    <SectionContainer style={{ backgroundColor: theme.colors[color] }}>
      <ContentContainer>{children}</ContentContainer>
    </SectionContainer>
  )
}

const SectionContainer = styled.div`
  padding: 80px;
  text-align: center;

  h2 {
    font-size: 45px;
  }

  @media (max-width: ${theme.breakpoints.m}) {
    padding: 40px 20px;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    h2 {
      font-size: 34px;
    }
  }
`

PageSection.propTypes = {
  children: PropTypes.array,
  color: PropTypes.string,
}

PageSection.defaultProps = {
  children: [],
  color: 'white',
}

export default PageSection
