import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { ContentContainer } from '../Layout'
import { theme } from '../../styles'
import styles from './Header.module.scss'

const PageHeader = ({ logoUrl }) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)

  return (
    <div>
      <ContentContainer>
        <div className={styles.header}>
          <Anchor to="/">
            <img className={styles.logo} src={logoUrl} alt="Company logo" />
          </Anchor>

          <div className={styles.desktopList}>
            <li>
              <Anchor to="/">Home</Anchor>
            </li>
            <li>
              <Anchor to="/about">About</Anchor>
            </li>
            <li>
              <Anchor to="/gallery">Gallery</Anchor>
            </li>
            <li>
              <Anchor to="/contact">Contact</Anchor>
            </li>
          </div>

          <div
            className={styles.burger}
            role="button"
            aria-pressed={isMenuExpanded}
            onClick={() => setIsMenuExpanded(!isMenuExpanded)}
          >
            <div />
            <div />
            <div />
          </div>
        </div>
      </ContentContainer>

      {isMenuExpanded ? (
        <div className={styles.mobileListContainer}>
          <ContentContainer>
            <div className={styles.mobileList}>
              <li>
                <Anchor to="/">Home</Anchor>
              </li>
              <li>
                <Anchor to="/about">About</Anchor>
              </li>
              <li>
                <Anchor to="/gallery">Gallery</Anchor>
              </li>
              <li>
                <Anchor to="/contact">Contact</Anchor>
              </li>
            </div>
          </ContentContainer>
        </div>
      ) : null}
    </div>
  )
}

const Anchor = styled(Link)`
  font-weight: 500;
  text-decoration: none;
  color: ${theme.colors.black};

  &:visited {
    color: ${theme.colors.black};
  }

  &:hover {
    color: ${theme.colors.logoRed};
  }
`

PageHeader.propTypes = {
  logoUrl: PropTypes.string.isRequired,
}

export default PageHeader
